<router-outlet>
  <div class="tabpromt">
    <p>
      Please log in from a laptop or desktop computer. The dashboard is not
      supported on mobile devices.
    </p>
  </div>
</router-outlet>
<ngx-spinner
  bdOpacity="0.9"
  bdColor="rgba(51,51,51,0.29)"
  size="medium"
  color="#fff"
  type="ball-beat"
  [fullScreen]="true"
>
  <p style="color: white">Loading</p>
</ngx-spinner>
